<template>
  <!-- Reporting -->
  <section id="reporting" class="my-10">
    <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20max-w-7xl mx-auto grid gap-12 row-gap-8 lg:grid-cols-2">
      <div class="flex flex-col justify-center">
        <div class="max-w-xl mb-6">
          <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
            All the reports you need. <br/>More coming.
          </h2>
          <p class="text-base text-gray-700 md:text-lg">
            We have built-in custom reports to serve your day to day accounting needs. Make good business decision with our customisable and exportable reports.
            Example reports we have are Statement of Account, General Ledger, Debtor Aging, and even SST-02 Report. More custom reports are coming soon too.
          </p>
        </div>
      </div>
      <div class="flex items-center justify-center -mx-4 lg:pl-8">
        <div class="px-3 relative">
          <img
              class="object-cover mb-6 rounded shadow-lg sm:absolute border-4 border-white" style="left: -100px; top: 150px;"
              :src="require('@/assets/img/features/report2.png')"/>

          <img class="object-cover w-96 rounded shadow-lg border-4 border-white"
               :src="require('@/assets/img/features/report1.png')"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Reporting"
}
</script>

<style scoped>

</style>
