<template>
  <!-- Email Reminders -->
  <section id="email_reminders" class="my-10">
    <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20max-w-7xl mx-auto grid gap-12 row-gap-8 lg:grid-cols-2">
      <div class="flex items-center justify-center -mx-4 lg:pl-8">
        <div class="px-3 relative">
          <img
              class="object-cover mb-6 rounded shadow-lg sm:absolute" style="left: -130px; top: -70px;"
              :src="require('@/assets/img/features/email1.png')"/>

          <img class="object-cover w-96 rounded shadow-lg border-4 border-white"
               :src="require('@/assets/img/features/email2.png')"/>
        </div>
      </div>
      <div class="flex flex-col justify-center">
        <div class="max-w-xl mb-6">
          <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
            Get paid on time with Email Reminders
          </h2>
          <p class="text-base text-gray-700 md:text-lg">
            You can schedule email reminders, system will automatically send out email reminders with email attached for all of your unpaid Invoices.
            Schedule automated emails on set periods like 7 days, 14 days, 30 days after past due.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
name: "EmailReminder"
}
</script>

<style scoped>

</style>
