<template>
  <section id="security" class="bg-gray-100 py-16">
    <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20max-w-7xl mx-auto grid gap-12 row-gap-8 lg:grid-cols-2">
      <div class="flex flex-col justify-center">
        <div class="max-w-xl mb-6">
          <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
            Peace of mind for your business.
          </h2>
          <p class="text-base text-gray-700 md:text-lg">
          </p>
        </div>
        <div class="grid gap-8 row-gap-8 sm:grid-cols-2">
          <div>
            <h6 class="mb-2 font-semibold leading-5  flex flex-row">
              <img :src="require('@/assets/img/icons/key.svg')" class="mr-2 w-4"/>
              PDPA Compliant
            </h6>
            <p class="text-sm text-gray-900">
              Your data, and your customer's data is our priority to keep it safe and secured.
            </p>
          </div>
          <div>
            <h6 class="mb-2 font-semibold leading-5  flex flex-row">
              <img :src="require('@/assets/img/icons/refresh-ccw.svg')" class="mr-2 w-4"/>
              Daily Backups
            </h6>
            <p class="text-sm text-gray-900">
              All your data is back-up daily in our secured servers.
            </p>
          </div>
          <div>
            <h6 class="mb-2 font-semibold leading-5  flex flex-row">
              <img :src="require('@/assets/img/icons/globe.svg')" class="mr-2 w-4"/>
              Cloud Infrastructure
            </h6>
            <p class="text-sm text-gray-900">
              We are on world-class Cloud Providers, ensuring reliability and performance no matter where you are.
            </p>
          </div>
          <div>
            <h6 class="mb-2 font-semibold leading-5 flex flex-row">
              <img :src="require('@/assets/img/icons/lock.svg')" class="mr-2 w-4"/> SSL Enabled
            </h6>
            <p class="text-sm text-gray-900">
              All communications between you and our servers are encrypted with industrial standards.
            </p>
          </div>
        </div>
      </div>
      <div>
        <img class="object-cover w-full h-56 rounded shadow-lg sm:h-96"
             :src="require('@/assets/img/peaceofmind.jpg')"
             alt="Peace of Mind for your business" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
name: "Security"
}
</script>

<style scoped>

</style>
