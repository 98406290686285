<template>
  <div class="features">
    <Nav></Nav>
    <section id="header" class="px-4 pt-20 pb-10 mx-auto items-center text-center">
      <h1 class="animate__animated animate__slideInTop max-auto mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
        Features<span class="text-green-500">.</span>
      </h1>
      <p class="animate__animated animate__slideInTop text-base text-gray-700 md:text-lg">
        Small Business Accounting features that scales.
      </p>
    </section>

    <div class="text-green-400 text-center items-center text-xl flex flex-col" style="letter-spacing: 2em;">
      <span>•</span>
      <span>•</span>
      <span>•</span>
    </div>

    <section class="max-w-7xl mx-auto">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div class="grid gap-8 row-gap-5 sm:row-gap-8 sm:grid-cols-2 lg:grid-cols-4">

            <div v-for="feature in features" :key="feature.title" class="relative block p-px overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 group hover:shadow-xl">
              <div class="absolute bottom-0 left-0 w-full h-1 duration-300 origin-left transform scale-x-0 bg-green-400 group-hover:scale-x-100"></div>
              <div class="absolute bottom-0 left-0 w-1 h-full duration-300 origin-bottom transform scale-y-0 bg-green-400 group-hover:scale-y-100"></div>
              <div class="absolute top-0 left-0 w-full h-1 duration-300 origin-right transform scale-x-0 bg-green-400 group-hover:scale-x-100"></div>
              <div class="absolute bottom-0 right-0 w-1 h-full duration-300 origin-top transform scale-y-0 bg-green-400 group-hover:scale-y-100"></div>
              <div class="h-full relative flex items-center justify-between p-5 bg-white rounded-sm">
                <div class="pr-4">
                  <h6 class="mb-2 font-semibold leading-5">
                    {{ feature.title }}
                  </h6>
                  <p class="text-sm text-gray-900">
                    {{ feature.description }}
                  </p>
                </div>
              </div>
            </div>

          </div><!--/.grid-->
        </div>
    </section>

    <section id="notable_features" class="mt-10 mb-20">
      <div class="w-full text-center">
        <h1 class="animate__animated animate__slideInTop max-auto mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
          Some of Our Notable Features
        </h1>
      </div>
    </section>

    <ScanToPay></ScanToPay>

    <EmailReminder></EmailReminder>

    <Reporting></Reporting>

    <Security></Security>

    <section id="sign_up" class="py-16">
      <SignUpBox></SignUpBox>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Nav from "@/views/components/Nav";
import SignUpBox from "@/views/components/SignUpBox";
import Footer from "@/views/components/Footer";
import features from "@/constants/FeatureList";
import ScanToPay from "@/views/components/ScanToPay";
import EmailReminder from "@/views/components/EmailReminder";
import Reporting from "@/views/components/Reporting";
import Security from "@/views/components/Security";

export default {
  components: {Security, Reporting, EmailReminder, ScanToPay, Footer, SignUpBox, Nav},
  data () {
    return {
      features: null
    }
  },
  created () {
    this.features = features
  }
}
</script>
