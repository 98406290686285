<template>
  <!-- payments -->
  <section id="payments" class="my-10">
    <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20max-w-7xl mx-auto grid gap-12 row-gap-8 lg:grid-cols-2">
      <div class="flex flex-col justify-center">
        <div class="max-w-xl mb-6">
          <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
            Scan to Pay
          </h2>
          <p class="text-base text-gray-700 md:text-lg">
            Allow you to create invoices with Payment QR code and send it to customers with a breeze.
            Customers will have access to a self service portal to make payment with the Payment gateway of your choice.
          </p>
          <div class="flex flex-row flex-no-wrap mt-3">
            <img class="m-1" :src="require('@/assets/img/features/visa.png')"/>
            <img class="m-1" :src="require('@/assets/img/features/master.png')"/>
            <img class="m-1 object-cover w-20" :src="require('@/assets/img/features/fpx.png')"/>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center -mx-4 lg:pl-8">
        <div class="px-3 relative">
          <img
              class="object-cover mb-6 rounded shadow-lg sm:absolute border-4 border-white" style="left: -130px; top: -70px;"
              :src="require('@/assets/img/features/scantoPay_qr.png')"/>

          <img class="object-cover w-96 rounded shadow-lg border-4 border-white"
               :src="require('@/assets/img/features/scantoPay2.png')"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
name: "ScanToPay"
}
</script>

<style scoped>

</style>
